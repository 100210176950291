import * as React from 'react';

import { GroupLabel } from '../GroupLabel';

interface Props {
  title: string;
  description?: string;
  style?: React.CSSProperties;
}

export const Group: React.FC<Props> = ({
  children,
  title,
  description,
  style,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      marginTop: 60,
      ...style,
    }}
  >
    {title && <GroupLabel title={title} description={description} />}
    <div
      style={{
        flex: 4,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  </div>
);
