import {
  Button,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import regionData from 'country-region-data';
import { cloneDeep, set } from 'lodash';
import * as React from 'react';

import { IToastContext } from '../../context/ToastContext';
import { Api } from '../../utils/Api';
import {
  ISocialAccount,
  ISocialAccountRate,
  ISocialAccountStat,
} from '../../utils/typings';
import { FormattedNumberInput } from '../FormattedNumberInput';
import { Group } from '../Group';
import { Row } from '../Row';

const emptyValues = {
  rates: {
    type: '',
    min: null,
    max: null,
    isActive: true,
  },
  stats: {
    metric: '',
    value: '',
  },
};

const emptySocialAccount = {
  username: '',
  type: '',
  rates: [],
  stats: [],
};

type NestedArrays = 'stats' | 'rates';

interface Props {
  state: any;
  setState: (state: any) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  tags: any[];
  title: string;
  toast: IToastContext;
}

export const PersonForm: React.FC<Props> = ({
  state,
  setState,
  tags,
  handleSubmit,
  title,
  toast,
}) => {
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleDateChange = (date: any) => {
    setState({ ...state, dateOfBirth: date?.toString() });
  };

  const handleTagChange = (event: any, newValue: any) => {
    // Backspace keypress
    if (event.keyCode === 8) {
      setState({
        ...state,
        tags: (state.tags || [])?.slice(0, -1),
      });
      return;
    }

    if (!newValue) {
      return;
    }

    setState({
      ...state,
      tags: [
        ...(state.tags || []),
        newValue.filter((option: any) => state.tags?.indexOf(option) === -1),
      ],
    });
  };

  const handleTagDelete = (index: number) => {
    setState({
      ...state,
      tags: state.tags?.filter((_n: any, i: number) => index !== i),
    });
  };

  const handleArrayAdd = () => {
    setState({
      ...state,
      socialAccounts: state.socialAccounts?.concat([emptySocialAccount]) as any,
    });
  };

  const handleNestedAdd = (type: NestedArrays, socialAccountIndex: number) => {
    setState({
      ...state,
      socialAccounts: state.socialAccounts?.map((node, sidx) => {
        if (sidx !== socialAccountIndex) {
          return node;
        }

        return {
          ...node,
          [type]: (node[type] as any)?.concat([emptyValues[type]]) || [
            emptyValues[type],
          ],
        };
      }),
    });
  };

  const handleNestedRemove = async (
    type: NestedArrays,
    socialAccountIndex: number,
    index: number,
    resourceId?: string,
  ) => {
    const confirm = window.confirm(
      'Are you sure? This action cannot be undone.',
    );
    if (!confirm) {
      return;
    }
    if (resourceId) {
      if (type === 'rates') {
        await Api.deletePersonRate(resourceId);
      }

      if (type === 'stats') {
        await Api.deletePersonStat(resourceId);
      }
    }

    setState({
      ...state,
      socialAccounts: state.socialAccounts?.map((node: any, sidx: number) => {
        if (socialAccountIndex !== sidx) {
          return node;
        }

        return {
          ...node,
          [type]:
            node?.[type]?.filter(
              (_type: any, nestedIndex: number) => index !== nestedIndex,
            ) || [],
        };
      }),
    });
  };

  const handleArrayRemove = async (index: number, socialAccountId: string) => {
    const confirm = window.confirm(
      'Are you sure? This action cannot be undone.',
    );
    if (!confirm) {
      return;
    }

    if (socialAccountId) {
      const { error } = await Api.deleteSocialAccount(socialAccountId);
      if (error) {
        toast.open({ message: 'Something went wrong', severity: 'error' });
        return;
      }
    }

    setState({
      ...state,
      socialAccounts: state.socialAccounts?.filter(
        (_s: any, sidx: number) => index !== sidx,
      ),
    });
  };

  const handleArrayChange = (name: string, value: string, index: number) => {
    const socialAccounts = state.socialAccounts?.map(
      (node: any, sidx: number) => {
        if (index !== sidx) {
          return node;
        }

        const nextNode = cloneDeep(node);
        set(nextNode, name, value);

        return nextNode;
      },
    );

    setState({ ...state, socialAccounts });
  };

  const handleNestedChange = (
    type: NestedArrays,
    name: string,
    value: string,
    socialAccountIndex: number,
    index: number,
  ) => {
    const socialAccounts = state.socialAccounts?.map(
      (node: any, sidx: number) => {
        if (socialAccountIndex !== sidx) {
          return node;
        }

        const nextNode = cloneDeep(node);
        const nextArray = nextNode?.[type]?.map(
          (nestedNode: any, ridx: number) => {
            if (index !== ridx) {
              return nestedNode;
            }

            const nextNestedNode = cloneDeep(nestedNode);
            set(nextNestedNode, name, value);

            return nextNestedNode;
          },
        );

        set(nextNode, type, nextArray);
        return nextNode;
      },
    );

    setState({ ...state, socialAccounts });
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        width: '60vw',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '5vh',
        padding: 24,
      }}
    >
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 48,
        }}
      >
        <Typography variant="h3">{title}</Typography>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth={false}
        >
          Submit
        </Button>
      </Row>
      <AboutForm
        state={state}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
      />
      <LocationForm state={state} handleChange={handleChange} />
      <TagsForm
        state={state}
        handleTagChange={handleTagChange}
        handleTagDelete={handleTagDelete}
        tags={tags}
      />
      <Group
        title="Socials"
        description={`Add any and all the accounts ${
          state.firstName || 'this person'
        } has. You need at least one for them to show up on the search Dashboard`}
      >
        {state.socialAccounts?.map((node: ISocialAccount, index: number) => (
          <SocialAccountForm
            key={index}
            socialAccount={node}
            index={index}
            handleArrayChange={handleArrayChange}
            handleArrayRemove={handleArrayRemove}
            handleNestedAdd={handleNestedAdd}
            handleNestedChange={handleNestedChange}
            handleNestedRemove={handleNestedRemove}
          />
        ))}

        <Button variant="contained" onClick={handleArrayAdd}>
          <AddIcon /> Add Social Account
        </Button>
      </Group>
    </form>
  );
};

interface IForm {
  handleChange?: (event: any) => void;
  handleTagChange?: (event: any, newValue: any) => void;
  handleTagDelete?: (event: any) => void;
  handleDateChange?: (event: any) => void;
  handleArrayChange?: (name: string, value: any, index: number) => void;
  handleNestedChange?: (
    type: NestedArrays,
    name: string,
    value: any,
    socialAccountIndex: number,
    rateIndex: number,
  ) => void;
  handleNestedAdd?: (type: NestedArrays, socialAccountIndex: number) => void;
  handleNestedRemove?: (
    type: NestedArrays,
    socialAccountIndex: number,
    rateIndex: number,
    resourceId?: string,
  ) => void;
  handleArrayRemove?: (
    index: number,
    socialAccountId?: string,
  ) => Promise<void>;
  socialAccountIndex?: number;
  index?: number;
  state?: any;
  rate?: ISocialAccountRate;
  stat?: ISocialAccountStat;
  socialAccount?: ISocialAccount;
  tags?: any[];
}

const AboutForm: React.FC<IForm> = ({
  handleChange,
  handleDateChange,
  state,
}) => (
  <Group title="About" description="Add a few basic details about this person.">
    <Row>
      <TextField
        type="firstName"
        placeholder="First Name"
        label="First Name"
        fullWidth
        name="firstName"
        variant="filled"
        value={state.firstName}
        onChange={handleChange}
        style={{ marginBottom: 12 }}
        autoFocus
        required
      />
      <TextField
        type="lastName"
        placeholder="Last Name"
        label="Last Name"
        fullWidth
        name="lastName"
        variant="filled"
        value={state.lastName}
        onChange={handleChange}
        style={{ marginBottom: 12 }}
      />
    </Row>
    <TextField
      type="email"
      placeholder="Email"
      label="Email"
      fullWidth
      name="email"
      variant="filled"
      value={state.email}
      onChange={handleChange}
      style={{ marginBottom: 12 }}
    />
    <TextField
      type="phoneNumber"
      placeholder="Phone Number"
      label="Phone Number"
      fullWidth
      name="phoneNumber"
      variant="filled"
      value={state.phoneNumber}
      onChange={handleChange}
      style={{ marginBottom: 12 }}
      required
    />
    <Row>
      <KeyboardDatePicker
        clearable
        value={state.dateOfBirth}
        placeholder="10/10/2018"
        label="Date of Birth"
        onChange={handleDateChange}
        format="MM/DD/yyyy"
        inputVariant="filled"
        fullWidth
        style={{ marginBottom: 12 }}
        disableFuture
      />
      <FormControl variant="filled" fullWidth>
        <InputLabel>Gender</InputLabel>
        <Select
          value={state.gender}
          onChange={handleChange}
          label="Gender"
          name="gender"
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
        </Select>
      </FormControl>
    </Row>
  </Group>
);

const LocationForm: React.FC<IForm> = ({ handleChange, state }) => (
  <Group
    title="Location"
    description="Some optional details to keep track of their locations"
  >
    <TextField
      type="addressLine1"
      placeholder="Address Line 1"
      label="Line 1"
      fullWidth
      name="addressLine1"
      variant="filled"
      value={state.addressLine1}
      onChange={handleChange}
      style={{ marginBottom: 12 }}
    />
    <TextField
      type="addressLine2"
      placeholder="Address Line 2"
      label="Line 2"
      fullWidth
      name="addressLine2"
      variant="filled"
      value={state.addressLine2}
      onChange={handleChange}
      style={{ marginBottom: 12 }}
    />
    <Row>
      <TextField
        type="city"
        placeholder="City"
        label="City"
        fullWidth
        name="city"
        variant="filled"
        value={state.city}
        onChange={handleChange}
        style={{ marginBottom: 12 }}
      />
      <TextField
        type="postalCode"
        placeholder="Postal Code"
        label="Postal Code"
        fullWidth
        name="postalCode"
        variant="filled"
        value={state.postalCode}
        onChange={handleChange}
        style={{ marginBottom: 12 }}
      />
    </Row>
    <Row>
      <FormControl variant="filled" style={{ width: '49%' }}>
        <InputLabel>Country</InputLabel>
        <Select
          label="Country"
          name="country"
          value={state.country}
          onChange={handleChange}
          fullWidth
        >
          {regionData.map((option) => (
            <MenuItem key={option.countryName} value={option.countryName}>
              {option.countryName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="filled" style={{ width: '49%' }}>
        <InputLabel>Region</InputLabel>
        <Select
          label="State"
          name="state"
          value={state.state}
          onChange={handleChange}
          fullWidth
        >
          {regionData
            ?.find((node) => node.countryName === state.country)
            ?.regions?.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Row>
  </Group>
);

const TagsForm: React.FC<IForm> = ({
  state,
  tags,
  handleTagChange,
  handleTagDelete,
}) => (
  <Group
    title="Tags"
    description="Add some tags to better organize this person"
  >
    <Autocomplete
      multiple
      options={tags?.map((option) => option)}
      freeSolo
      value={state?.tags || []}
      onChange={handleTagChange}
      renderTags={(value: string[], getTagProps) =>
        value?.map((option: string, index: number) => (
          <Chip
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            onDelete={() => handleTagDelete(index)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} variant="filled" label="Tags" name="tags" />
      )}
    />
  </Group>
);

const SocialAccountForm: React.FC<IForm> = ({
  socialAccount,
  handleArrayChange,
  handleNestedChange,
  handleNestedRemove,
  handleNestedAdd,
  handleArrayRemove,
  index,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Row style={{ justifyContent: 'center' }}>
      <FormControl variant="filled" style={{ flex: 3 }}>
        <InputLabel>Type</InputLabel>
        <Select
          label="Type"
          required
          value={socialAccount.type}
          onChange={(e: any) =>
            handleArrayChange('type', e.target.value, index)
          }
        >
          <MenuItem value="Instagram">Instagram</MenuItem>
          <MenuItem value="YouTube">YouTube</MenuItem>
          <MenuItem value="TikTok">TikTok</MenuItem>
        </Select>
      </FormControl>
      <TextField
        type="username"
        placeholder="Username"
        label="Username"
        variant="filled"
        style={{ flex: 5 }}
        value={socialAccount.username}
        onChange={(e: any) =>
          handleArrayChange('username', e.target.value, index)
        }
      />
      <IconButton onClick={() => handleArrayRemove(index, socialAccount.id)}>
        <DeleteIcon />
      </IconButton>
    </Row>
    <div style={{ marginLeft: 32, marginTop: 32 }}>
      <Typography variant="h6">Rates</Typography>
      {socialAccount?.rates?.map((node: any, ridx: number) => (
        <RateForm
          key={ridx}
          rate={node}
          index={ridx}
          socialAccountIndex={index}
          handleNestedChange={handleNestedChange}
          handleArrayRemove={handleArrayRemove}
          handleNestedRemove={handleNestedRemove}
        />
      ))}
      <Button
        variant="contained"
        onClick={() => handleNestedAdd('rates', index)}
      >
        <AddIcon /> Add Rate
      </Button>
    </div>
    <div style={{ marginLeft: 32, marginTop: 32 }}>
      <Typography variant="h6">Stats</Typography>
      {socialAccount?.stats?.map((node: any, midx: number) => (
        <StatsForm
          key={midx}
          stat={node}
          index={midx}
          socialAccountIndex={index}
          handleNestedChange={handleNestedChange}
          handleArrayRemove={handleArrayRemove}
          handleNestedRemove={handleNestedRemove}
        />
      ))}
      <Button
        variant="contained"
        onClick={() => handleNestedAdd('stats', index)}
      >
        <AddIcon /> Add Stat
      </Button>
    </div>
    <Divider style={{ marginTop: 24, marginBottom: 24 }} />
  </div>
);

const RateForm: React.FC<IForm> = ({
  rate,
  handleNestedChange,
  handleNestedRemove,
  socialAccountIndex,
  index,
}) => (
  <Row style={{ marginBottom: 12 }}>
    <FormControl variant="filled" style={{ flex: 5 }}>
      <InputLabel>Type</InputLabel>
      <Select
        label="Type"
        value={rate.type}
        onChange={(e: any) =>
          handleNestedChange(
            'rates',
            'type',
            e.target.value,
            socialAccountIndex,
            index,
          )
        }
      >
        <MenuItem value="StoryOneFrame">Story (1 Frame)</MenuItem>
        <MenuItem value="StoryThreeFrame">Story (3 Frame)</MenuItem>
        <MenuItem value="Feed">Feed</MenuItem>
        <MenuItem value="Integration">Integration</MenuItem>
        <MenuItem value="Dedicated">Dedicated</MenuItem>
      </Select>
    </FormControl>
    <TextField
      type="text"
      placeholder="0"
      label="Min"
      variant="filled"
      style={{ flex: 4 }}
      value={rate.min}
      required
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        inputComponent: FormattedNumberInput as any,
      }}
      onChange={(e: any) =>
        handleNestedChange(
          'rates',
          'min',
          e.target.value,
          socialAccountIndex,
          index,
        )
      }
    />
    <TextField
      type="text"
      placeholder="0"
      label="Max"
      variant="filled"
      style={{ flex: 4 }}
      value={rate.max}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        inputComponent: FormattedNumberInput as any,
      }}
      onChange={(e: any) =>
        handleNestedChange(
          'rates',
          'max',
          e.target.value,
          socialAccountIndex,
          index,
        )
      }
    />
    <IconButton
      onClick={() =>
        handleNestedRemove('rates', socialAccountIndex, index, rate.id)
      }
    >
      <DeleteIcon />
    </IconButton>
  </Row>
);

const StatsForm: React.FC<IForm> = ({
  stat,
  handleNestedChange,
  handleNestedRemove,
  socialAccountIndex,
  index,
}) => (
  <Row style={{ marginBottom: 12 }}>
    <FormControl variant="filled" style={{ flex: 5 }}>
      <InputLabel>Metric</InputLabel>
      <Select
        value={stat.metric}
        onChange={(e: any) =>
          handleNestedChange(
            'stats',
            'metric',
            e.target.value,
            socialAccountIndex,
            index,
          )
        }
      >
        <MenuItem value="FollowerCount">Follower Count</MenuItem>
        <MenuItem value="StoryViews">Story Views</MenuItem>
        <MenuItem value="EngagementRate">Engagement Rate</MenuItem>
      </Select>
    </FormControl>
    <TextField
      type="text"
      placeholder="0"
      label="Value"
      variant="filled"
      style={{ flex: 4 }}
      value={stat.value}
      InputProps={{
        inputComponent: FormattedNumberInput as any,
      }}
      onChange={(e: any) =>
        handleNestedChange(
          'stats',
          'value',
          e.target.value,
          socialAccountIndex,
          index,
        )
      }
    />
    <IconButton
      onClick={() =>
        handleNestedRemove('stats', socialAccountIndex, index, stat.id)
      }
    >
      <DeleteIcon />
    </IconButton>
  </Row>
);
