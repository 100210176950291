import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  ChevronLeft,
  ImportExport,
  Menu,
  People,
  PersonAdd,
  PowerSettingsNew,
  QuestionAnswer,
  Sms,
} from '@material-ui/icons';
import clsx from 'clsx';
import * as React from 'react';

import { ImportModal } from '../ImportModal';

interface Props {
  title?: string;
  children: React.ReactNode;
  history: any;
}
const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offset: theme.mixins.toolbar,
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    mainWrapper: {
      display: 'flex',
      height: '100%',
      overflowY: 'auto',
      scrollBehavior: 'smooth',
    },
    appWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
  }),
);

export const DashboardWrapper: React.FC<Props> = ({
  title,
  children,
  history,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [importModalIsOpen, setImportModalIsOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleImportModal = () => {
    setImportModalIsOpen(!importModalIsOpen);
  };

  const renderMenuItem: React.FC<any> = ({ text, link, icon: Icon }) => (
    <ListItem button key={text} onClick={() => history.push(link)}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );

  const menuItems = {
    people: [
      { text: 'People', link: '/people', icon: () => <People /> },
      {
        text: 'Create Person',
        link: '/people/new',
        icon: () => <PersonAdd />,
      },
    ],
    conversations: [
      {
        text: 'Conversations',
        link: '/conversations',
        icon: () => <QuestionAnswer />,
      },
      { text: 'Campaigns', link: '/campaigns', icon: () => <Sms /> },
    ],
  };

  return (
    <>
      <ImportModal isOpen={importModalIsOpen} closeModal={toggleImportModal} />
      <div className={classes.appWrapper}>
        <AppBar
          position="relative"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <Menu />
            </IconButton>
            <Typography variant="h6">{title}</Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={open}
          onBackdropClick={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <span style={{ flex: 1, paddingLeft: 10 }}>SparkText Platform</span>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          <List>{menuItems.conversations.map(renderMenuItem)}</List>
          <Divider />
          <List>{menuItems.people.map(renderMenuItem)}</List>
          <Divider />
          <List>
            <ListItem button onClick={toggleImportModal}>
              <ListItemIcon>
                <ImportExport />
              </ListItemIcon>
              <ListItemText primary="Import People" />
            </ListItem>
          </List>
          <div style={{ flex: 1 }} />
          <Divider />
          <List>
            <ListItem
              button
              onClick={() => {
                localStorage.removeItem('token');
                history.push('/');
              }}
            >
              <ListItemIcon>
                <PowerSettingsNew />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.mainWrapper}>{children}</main>
      </div>
    </>
  );
};
