import {
  AppBar,
  Button,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { ToastContext } from '../../context/ToastContext';
import { Api } from '../../utils/Api';

export const Login = () => {
  const history = useHistory();
  const toast = React.useContext(ToastContext);

  const [state, setState] = React.useState({
    email: '',
    password: '',
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { data, error } = await Api.login(state.email, state.password);

    if (error) {
      toast.open({ message: error, severity: 'error' });
      return;
    }

    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    Api.setEnvironment(data.token);

    toast.open({ message: 'Success!' });
    history.push('/people');
  };

  return (
    <>
      <AppBar position="relative" color="primary">
        <Toolbar>
          <Grid container justify="center" wrap="wrap">
            <Grid item>
              <Typography variant="h6">Spark Text</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: '90vw', width: '300px' }}
        >
          <TextField
            type="email"
            placeholder="Email"
            label="Email"
            fullWidth
            name="email"
            variant="filled"
            value={state.email}
            onChange={handleChange}
            required
            autoFocus
          />
          <TextField
            type="password"
            placeholder="Password"
            label="Password"
            fullWidth
            name="password"
            variant="filled"
            value={state.password}
            onChange={handleChange}
            required
            style={{ marginTop: 16 }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            style={{ marginTop: 16 }}
          >
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};
