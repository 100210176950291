import { Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
  text: string;
  sentAt: string;
  isSender: boolean;
  isBroadcast?: boolean;
}

export const Message: React.FC<Props> = ({
  text,
  sentAt,
  isSender,
  isBroadcast,
}) => {
  const backgroundColor = isSender
    ? isBroadcast
      ? '#ff0972'
      : '#FFCA84'
    : '#CFCFCF';
  const alignItems = isSender ? 'flex-end' : 'flex-start';
  const borderRadius = isSender ? '12px 12px 0 12px' : '12px 12px 12px 0';
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems,
      }}
    >
      <div
        style={{
          borderRadius,
          backgroundColor,
          padding: 16,
          marginTop: 16,
          maxWidth: '60%',
          lineBreak: 'anywhere',
        }}
      >
        <Typography variant="body1" style={{ color: '#000' }}>
          {text}
        </Typography>
      </div>
      <Typography variant="body2">{sentAt}</Typography>
    </div>
  );
};
