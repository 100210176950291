export const formatCurrency = (num: number, currency: string = 'USD') =>
  new Intl.NumberFormat('en', {
    currency,
    style: 'currency',
    notation: 'compact',
    useGrouping: true,
  } as any).format(num);

export const formatPercentage = (num: number) =>
  new Intl.NumberFormat('en', {
    type: 'integer',
    style: 'percent',
    useGrouping: true,
    notation: 'compact',
  } as any).format(num / 100);

export const formatInteger = (num: number) =>
  new Intl.NumberFormat('en', {
    type: 'integer',
    useGrouping: true,
    notation: 'compact',
  } as any).format(num);

export const formatNumbers = (
  style: 'currency' | 'percent' | 'integer',
  num: number,
) => {
  if (style === 'percent') {
    return formatPercentage(num);
  }

  if (style === 'currency') {
    return formatCurrency(num);
  }

  if (style === 'integer') {
    return formatInteger(num);
  }
};
