import moment from 'moment';

import { getSocialLink } from './getSocialLink';
import {
  ICampaign,
  ILocation,
  ISocialAccount,
  MetricType,
  RateType,
} from './typings';

const getLocation = (location: ILocation) => {
  const city = location?.city ? `${location.city}, ` : '';
  const state = location?.state ? `${location.state}, ` : '';
  const country = location?.country ? `${location.country}` : '';

  return city + state + country;
};

const getStat = (account: ISocialAccount, metric: MetricType) =>
  account?.stats?.filter((node) => node?.metric === metric)?.[0]?.value;

const getRate = (account: ISocialAccount, rateType: RateType) => {
  const rate = account?.rates?.filter((node) => node?.type === rateType)?.[0];
  return [rate?.min, rate?.max];
};

export const getCsvDataCampaign = (campaign: ICampaign) => {
  const headings = [
    'Name',
    'Platform',
    'Link',
    'Location',
    'Tags',
    'Age',
    'Engagement Rate',
    'Followers',
    'Story Views',
    'Feed Min',
    'Feed Max',
    'Story (1 Frame) Min',
    'Story (1 Frame) Max',
    'Story (3 Frame) Min',
    'Story (3 Frame) Max',
    'Dedicated Min',
    'Dedicated Max',
    'Integration Min',
    'Integration Max',
  ];

  const data = [];

  campaign?.recipients?.map((person) => {
    const name = `${person?.firstName} ${person?.lastName}`;
    const location = getLocation(person?.location);
    const tags = person?.tags?.join(', ');
    const age =
      person?.dateOfBirth && moment(person?.dateOfBirth)?.fromNow(true);

    person?.socialAccounts?.map((account) => {
      const platform = account?.type;
      const link = getSocialLink(account);

      const engagementRate = getStat(account, MetricType.EngagementRate);
      const followers = getStat(account, MetricType.FollowerCount);
      const storyViews = getStat(account, MetricType.StoryViews);
      const [feedMin, feedMax] = getRate(account, RateType.Feed);
      const [oneMin, oneMax] = getRate(account, RateType.StoryOneFrame);
      const [threeMin, threeMax] = getRate(account, RateType.StoryThreeFrame);
      const [dedicatedMin, dedicatedMax] = getRate(account, RateType.Dedicated);
      const [intMin, intMax] = getRate(account, RateType.Integration);

      data.push([
        name,
        platform,
        link,
        location,
        tags,
        age,
        engagementRate,
        followers,
        storyViews,
        feedMin,
        feedMax,
        oneMin,
        oneMax,
        threeMin,
        threeMax,
        dedicatedMin,
        dedicatedMax,
        intMin,
        intMax,
      ]);
    });
  });

  return [headings, ...data];
};
