import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { uniqBy } from 'lodash';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { CreateCampaignModal } from '../../components/CreateCampaignModal';
import { DashboardWrapper } from '../../components/DashboardWrapper';
import { ToastContext } from '../../context/ToastContext';
import { Api } from '../../utils/Api';
import { getStat } from '../../utils/getStat';

import { socialListTableColumns, socialListTableOptions } from './table';
import { IPerson } from '../../utils/typings';

const normalizePeople = (people: IPerson[]) => {
  const normalized = [];

  people.map((person) => {
    const normalPerson = {
      id: person?.id,
      name: `${person?.firstName} ${person?.lastName}`,
      tags: person?.tags || [],
      location: person?.location || {},
      dateOfBirth: person?.dateOfBirth || new Date(),
      person,

      // Social Account Defaults
      username: '',
      engagementRate: 0,
      followerCount: 0,
      storyViews: 0,
      rateValues: [],
      rateTypes: [],
      socialAccountType: 'No Social Accounts',
    };

    if (person?.socialAccounts.length === 0) {
      return normalized.push(normalPerson);
    }

    return person?.socialAccounts?.map((socialAccount) =>
      normalized.push({
        ...normalPerson,
        username: socialAccount?.username,
        socialAccountType: socialAccount?.type,
        engagementRate:
          getStat(socialAccount.stats, 'EngagementRate')?.value || 0,
        followerCount:
          getStat(socialAccount.stats, 'FollowerCount')?.value || 0,
        storyViews: getStat(socialAccount.stats, 'StoryViews')?.value || 0,
        rateValues: socialAccount.rates,
        rateTypes: socialAccount.rates?.map(({ type }) => type),
      }),
    );
  });

  return normalized;
};

const CustomChip = ({ label, onDelete }) => {
  return (
    <Chip
      variant="default"
      color="primary"
      label={label}
      onDelete={onDelete}
      style={{ marginRight: 6, marginBottom: 3, marginTop: 3 }}
    />
  );
};

const CustomFilterList = (props: any) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

export const PeopleScreen = () => {
  const history = useHistory();

  const toast = React.useContext(ToastContext);

  const [people, setPeople] = React.useState([]);
  const [campaigns, setCampaigns] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = React.useState(
    false,
  );

  const [newCampaignState, setNewCampaignState] = React.useState({
    name: '',
    description: '',
    status: 'draft',
  });

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const init = async () => {
    const [
      { data: peopleData, error: peopleError },
      { data: campaignsData, error: campaignsError },
    ] = await Promise.all([Api.getPeople(), Api.getCampaigns()]);

    if (peopleError || campaignsError) {
      toast.open({
        message: peopleError || campaignsError,
        severity: 'error',
      });
      return;
    }

    const normalizedPeople = normalizePeople(peopleData.people);

    setPeople(normalizedPeople);
    setCampaigns(campaignsData.campaigns);
  };

  const handleRowSelectionChange = (_current: any, all: any) => {
    const newSelectedPeople = all.map(({ dataIndex }) =>
      people.find((_n, i) => i === dataIndex),
    );
    setSelectedRows(uniqBy(newSelectedPeople, (node) => node?.person?.id));
  };

  const handleRemoveSelectedPerson = (personId: string) => {
    const removed = selectedRows.filter((node) => {
      return node?.person?.id !== personId;
    });
    setSelectedRows(removed);
  };

  const handleSelectCampaign = (campaignId: string) => {
    if (selectedCampaignId === campaignId) {
      setSelectedCampaignId(null);
      return;
    }

    setSelectedCampaignId(campaignId);
  };

  const handleSaveToCampaign = async () => {
    const { data, error } = await Api.updateCampaignParticipants({
      campaignId: selectedCampaignId,
      recipients: selectedRows.map(({ person }) => person?.id),
    });

    if (error) {
      toast.open({ message: error, severity: 'error' });
      return;
    }

    init();
    toast.open({ message: 'Success', severity: 'success' });

    setIsModalOpen(false);
    setSelectedCampaignId(null);
    setSelectedRows([]);
    history.push(`/campaigns/${data?.campaign?.id}`);
  };

  const handleCreateCampaign = async () => {
    const { data, error } = await Api.createCampaign({
      name: newCampaignState.name,
      description: newCampaignState.description,
      status: newCampaignState.status,
      recipients: selectedRows.map(({ person }) => person?.id),
    });

    if (error) {
      toast.open({ message: error, severity: 'danger' });
      return;
    }

    init();

    setNewCampaignState({
      name: '',
      description: '',
      status: '',
    });
    setIsNewCampaignModalOpen(false);
    toast.open({
      message: 'Success',
      severity: 'success',
    });

    history.push(`/campaigns/${data?.campaign?.id}`);
  };

  const handleInputChange = (event: any) => {
    setNewCampaignState({
      ...newCampaignState,
      [event.target.name]: event.target.value,
    });
  };

  const columns = socialListTableColumns(people, history);
  const options = socialListTableOptions(
    handleRowSelectionChange,
    selectedRows.map((node) => people.indexOf(node)),
    setIsModalOpen,
  );

  return (
    <DashboardWrapper title="Dashboard" history={history}>
      <React.Suspense fallback={<CircularProgress />}>
        <div style={{ flex: 1, height: '100%', padding: 24 }}>
          <MUIDataTable
            title="People"
            data={people}
            columns={columns}
            options={options}
            components={{
              TableFilterList: CustomFilterList,
            }}
          />
        </div>

        {/* First modal */}
        <Dialog
          fullWidth={true}
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedCampaignId(null);
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <DialogTitle>Add People To Campaign</DialogTitle>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setIsModalOpen(false);
                setIsNewCampaignModalOpen(true);
              }}
              style={{ marginRight: 24 }}
            >
              Create New Campaign
            </Button>
          </div>
          <DialogContent style={{ padding: 24 }}>
            {selectedRows.map((selectedPerson) => (
              <Chip
                key={selectedPerson?.person?.id}
                label={selectedPerson?.name}
                variant="default"
                color="secondary"
                onDelete={() =>
                  handleRemoveSelectedPerson(selectedPerson?.person?.id)
                }
                style={{ marginRight: 8, marginBottom: 8 }}
              />
            ))}
            <div style={{ marginBottom: 16 }} />
            {campaigns.map((campaign) => {
              const isSelectedCampaign = selectedCampaignId === campaign.id;

              return (
                <ListItem
                  key={campaign.id}
                  button
                  selected={isSelectedCampaign}
                  onClick={() => handleSelectCampaign(campaign.id)}
                >
                  <ListItemText
                    primary={campaign.name}
                    secondary={campaign.description}
                  />
                </ListItem>
              );
            })}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 16,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedCampaignId || !selectedRows.length}
                onClick={handleSaveToCampaign}
              >
                Save
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        <CreateCampaignModal
          isOpen={isNewCampaignModalOpen}
          newCampaignState={newCampaignState}
          handleInputChange={handleInputChange}
          handleCreateCampaign={handleCreateCampaign}
          handleClose={() => setIsNewCampaignModalOpen(false)}
        />
      </React.Suspense>
    </DashboardWrapper>
  );
};
