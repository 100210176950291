import * as React from 'react';
import { CSVLink } from 'react-csv';
import {
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Chip,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import { ICampaign } from '../../utils/typings';
import { getCsvDataCampaign } from '../../utils/getCsvDataCampaign';

interface Props {
  campaign: ICampaign;
}

const useStyles = makeStyles((_theme) => ({
  root: {
    flex: 1,
  },
  iconRoot: {
    minWidth: 0,
    marginRight: 16,
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    lineHeight: 1,
  },
  listSubHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 12,
    lineHeight: 1,
  },
  listItem: {
    paddingLeft: 32,
  },
  emptyWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.5,
  },
}));

export const InboxCampaignDetails: React.FC<Props> = ({ campaign }) => {
  const classes = useStyles();
  const csvData = getCsvDataCampaign(campaign);
  const date = moment().format('LLL');
  const filename = `${campaign?.name}-${campaign?.status}-${date}.csv`;

  if (!campaign) {
    return (
      <div className={classes.emptyWrapper}>
        <Typography variant="body1">Select a campaign</Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 24,
          padding: 12,
        }}
      >
        <Typography variant="h5" style={{ flex: 1 }}>
          {campaign.name}
        </Typography>
        <Chip label={campaign.status} />
      </div>
      <Typography variant="body2" style={{ padding: 12 }}>
        {campaign.description}
      </Typography>
      <div style={{ padding: 12 }}>
        <CSVLink
          data={csvData}
          filename={filename}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">Export Influencers as CSV</Button>
        </CSVLink>
      </div>
      <List>
        <ListSubheader>PEOPLE</ListSubheader>
        {campaign?.recipients.map((person: any) => (
          <ListItem>
            <ListItemText
              primary={`${person.firstName} ${person.lastName}`}
              secondary={person.phoneNumber}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
