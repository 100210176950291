import { Typography } from '@material-ui/core';
import * as React from 'react';

export const NotFound = () => {
  return (
    <div>
      <Typography component="h1" variant="h5">
        Page Not Found
      </Typography>
    </div>
  );
};
