export enum CampaignStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
}

export enum SocialAccountEnum {
  Instagram = 'Instagram',
  YouTube = 'YouTube',
  TikTok = 'TikTok',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum RateType {
  StoryOneFrame = 'StoryOneFrame',
  StoryThreeFrame = 'StoryThreeFrame',
  Feed = 'Feed',
  Integration = 'Integration',
  Dedicated = 'Dedicated',
}

export interface ILocation {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

export enum MetricType {
  FollowerCount = 'FollowerCount',
  StoryViews = 'StoryViews',
  EngagementRate = 'EngagementRate',
}

export interface IBroadcastMessage {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  body: string;
  sentAt: Date;
  messages: IMessage[];
  campaign: ICampaign;
  user: IUser;
}

export interface ICampaign {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  description: string;
  status: CampaignStatus;
  broadcastMessages: IBroadcastMessage[];
  recipients: IPerson[];
}

export interface IConversation {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  messages: IMessage[];
  person: IPerson;
}

export interface IMessage {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  body: string;
  twilioId: string;
  conversation: IConversation;
  broadcastMessage: IBroadcastMessage;
  user: IUser;
  person: IPerson;
}

export interface IPerson {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  gender: Gender;
  dateOfBirth: Date;
  location: ILocation;
  tags: string[];
  socialAccounts: ISocialAccount[];
  messages: IMessage[];
  conversation: IConversation;
  campaigns: ICampaign[];
}

export interface ISocialAccount {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  username: string;
  type: SocialAccountEnum;
  rates: ISocialAccountRate[];
  person: IPerson;
  stats: ISocialAccountStat[];
}

export interface ISocialAccountRate {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  min: number;
  max: number;
  isActive: boolean;
  type: RateType;
  account: ISocialAccount;
}

export interface ISocialAccountStat {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  value: number;
  metric: MetricType;
  account: ISocialAccount;
}

export interface IUser {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  messages: IMessage[];
  broadcastMessages: IBroadcastMessage[];
}
