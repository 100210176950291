import {
  Button,
  List,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { sortBy } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { DashboardWrapper } from '../../components/DashboardWrapper';
import { Message } from '../../components/Message';
import { MessagePreview } from '../../components/MessagePreview';
import { Row } from '../../components/Row';
import { ToastContext } from '../../context/ToastContext';
import { useInboxPoll } from '../../hooks/useInboxPoll';
import { Api } from '../../utils/Api';
import { InboxConversationDetails } from '../../components/InboxConversationDetails';
import { InboxCampaignDetails } from '../../components/InboxCampaignDetails';

export const Inbox = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const toast = React.useContext(ToastContext);
  const { conversations, campaigns } = useInboxPoll({
    doPolling: true,
  });

  const [currentTab, setCurrentTab] = React.useState('conversations');
  const [newMessage, setNewMessage] = React.useState('');
  const [selectedCampaign, setSelectedCampaign] = React.useState(null);
  const [selectedConversation, setSelectedConversation] = React.useState(null);

  const scrollBottomElement = React.createRef();

  const scrollToBottom = () => {
    const ele = (document as any).querySelector(
      '.sparktext-conversation-scrollBottom',
    );
    if (ele) {
      setTimeout(() => ele?.scrollIntoView({ behavior: 'smooth' }), 0);
    }
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [conversations, campaigns, location.pathname]);

  const init = async () => {
    const campaign = campaigns?.find((c: any) => c.id === match.params?.id);
    const conversation = conversations?.find(
      (c: any) => c.id === match.params?.id,
    );
    if (location.pathname.includes('campaigns')) {
      setCurrentTab('campaigns');
    }
    if (location.pathname.includes('conversations')) {
      setCurrentTab('conversations');
    }

    if (campaign) {
      setSelectedCampaign(campaign);
      return;
    }
    if (conversation) {
      setSelectedConversation(conversation);
      return;
    }
  };

  const handleTabChange = (_event: any, value: any) => {
    setCurrentTab(value);
    history.push(`/${value}`);
    setSelectedCampaign(null);
    setSelectedConversation(null);
  };

  const handleMessagePreviewClick = (id: string) => {
    history.push(`/${currentTab}/${id}`);

    const campaign = campaigns?.find((c) => c.id === id);
    const conversation = conversations?.find((c) => c.id === id);

    if (campaign) {
      setSelectedConversation(null);
      setSelectedCampaign(campaign);
      return;
    }

    if (conversation) {
      setSelectedCampaign(null);
      setSelectedConversation(conversation);
      return;
    }
  };

  const handleInputChange = (event: any) => {
    setNewMessage(event.target.value);
  };

  const handleCreateMessage = async (event: any) => {
    event.preventDefault();

    if (newMessage.length >= 1600) {
      toast.open({
        message: 'Your message is over the character limit',
        severity: 'error',
      });
      return;
    }

    const stringifiedUser = localStorage.getItem('user');
    const user = JSON.parse(stringifiedUser);

    if (currentTab === 'conversations') {
      const { error } = await Api.createMessage({
        body: newMessage,
        conversationId: selectedConversation?.id,
        personId: selectedConversation?.person?.id,
      });

      if (error) {
        toast.open({ message: error, severity: 'error' });
        return;
      }
    }

    if (currentTab === 'campaigns') {
      const { error } = await Api.createBroadcastMessage({
        body: newMessage,
        campaignId: selectedCampaign.id,
        userId: user.id,
      });

      if (error) {
        toast.open({ message: error, severity: 'error' });
        return;
      }
    }

    setNewMessage('');
    init();
  };

  const realMessages =
    selectedCampaign?.broadcastMessages || selectedConversation?.messages;
  const actualRealMessages = sortBy(realMessages, ['createdAt']);

  const normalizeMessagePreviews = (type: 'campaigns' | 'conversations') => {
    const normalizedMessages = [];

    try {
      const nodes = type === 'campaigns' ? campaigns : conversations;

      nodes.map((node: any) => {
        const msgs =
          type === 'campaigns' ? node.broadcastMessages : node.messages;

        const person = node?.person;
        const personName = person
          ? `${person?.firstName} ${person?.lastName}`
          : 'No Person';

        const lastMessage: any = msgs[msgs.length - 1];
        const finalLastMessage =
          lastMessage?.body?.length > 60
            ? `${lastMessage?.body?.substr(0, 60)}...`
            : lastMessage?.body;

        return normalizedMessages.push({
          id: node.id,
          name: type === 'campaigns' ? node.name : personName,
          lastMessage: finalLastMessage,
          lastMessageSentAt: lastMessage?.createdAt,
          recipientCount: type === 'campaigns' ? node.recipients.length : null,
        });
      });

      return normalizedMessages;
    } catch (error) {
      return [];
    }
  };

  const messagePreviews = normalizeMessagePreviews(currentTab as any);

  const activeCampaignOrConversation =
    !!selectedCampaign?.id || !!selectedConversation?.id;
  const isDisabled = !newMessage || !activeCampaignOrConversation;

  return (
    <DashboardWrapper title="Conversations" history={history}>
      <Row style={{ flex: 1 }}>
        <div style={{ flex: 1 }}>
          <Paper square>
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              style={{ marginBottom: -8 }}
            >
              <Tab
                label="Conversations"
                value="conversations"
                style={{ width: '50%' }}
              />
              <Tab
                label="Campaigns"
                value="campaigns"
                style={{ width: '50%' }}
              />
            </Tabs>
          </Paper>
          <List>
            {messagePreviews
              .sort(
                (a, b) =>
                  moment(b.lastMessageSentAt).unix() -
                  moment(a.lastMessageSentAt).unix(),
              )
              .map((node) => {
                return (
                  <MessagePreview
                    key={node.id}
                    name={node.name}
                    lastMessage={node.lastMessage}
                    lastMessageSentAt={moment(node.lastMessageSentAt).fromNow()}
                    recipientCount={node?.recipientCount}
                    onClick={() => handleMessagePreviewClick(node.id)}
                    selected={node.id === match.params.id}
                  />
                );
              })}
          </List>
        </div>
        <div
          style={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flex: 1, overflowY: 'auto', padding: 24 }}>
            {(actualRealMessages || []).map((node: any) => (
              <Message
                key={node?.id}
                text={node?.body}
                sentAt={moment(node?.createdAt).fromNow()}
                isSender={!node.person?.id}
                isBroadcast={!!node?.broadcastMessage}
              />
            ))}
            <div
              className="sparktext-conversation-scrollBottom"
              ref={() => scrollBottomElement?.current}
            />
          </div>
          <div>
            <TextField
              multiline
              rows={8}
              variant="filled"
              fullWidth
              value={newMessage}
              onChange={handleInputChange}
            />
            <Row
              style={{
                marginTop: 24,
                marginBottom: 24,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <Typography variant="body1">
                  {newMessage.length} of 1600
                </Typography>
              </div>

              <Button
                variant="contained"
                disabled={isDisabled}
                onClick={handleCreateMessage}
              >
                Send
              </Button>
            </Row>
          </div>
        </div>
        <div style={{ display: 'flex', flex: 1, overflowY: 'auto' }}>
          {currentTab === 'campaigns' ? (
            <InboxCampaignDetails campaign={selectedCampaign} />
          ) : (
            <InboxConversationDetails person={selectedConversation?.person} />
          )}
        </div>
      </Row>
      {scrollToBottom()}
    </DashboardWrapper>
  );
};
