import { uniq } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { DashboardWrapper } from '../../components/DashboardWrapper';
import { PersonForm } from '../../components/PersonForm';
import { ToastContext } from '../../context/ToastContext';
import { Api } from '../../utils/Api';

export const CreatePerson = () => {
  const toast = React.useContext(ToastContext);
  const history = useHistory();

  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    dateOfBirth: new Date(),

    // location
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',

    socialAccounts: [],
    tags: [],
  });
  const [tags, setTags] = React.useState([]);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const { data } = await Api.getTags();
      setTags(uniq(data.data.tags?.flat()));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        gender,
        dateOfBirth,
        addressLine1,
        addressLine2,
        city,
        postalCode,
        country,
        socialAccounts,
      } = state;
      const { error } = await Api.createPerson({
        firstName,
        lastName,
        email,
        phoneNumber,
        gender: gender === '' ? null : gender,
        dateOfBirth,
        tags: state.tags?.flat(),
        location: {
          addressLine1,
          addressLine2,
          city,
          state: state.state,
          postalCode,
          country,
        },
        socialAccounts,
      });

      if (error) {
        throw new Error(error);
      }

      toast.open({ message: `Successfully added ${firstName}` });
      history.push('/people');
    } catch (error) {
      toast.open({ message: error?.message, severity: 'error' });
    }
  };

  return (
    <DashboardWrapper title="Add a Person" history={history}>
      <div style={{ width: '100%', paddingBottom: '5vh' }}>
        <PersonForm
          title="Add a Person"
          state={state}
          setState={setState}
          tags={tags}
          handleSubmit={handleSubmit}
          toast={toast}
        />
      </div>
    </DashboardWrapper>
  );
};
