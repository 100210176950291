import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import * as React from 'react';

import { Row } from '../Row';

export const CreateCampaignModal: React.FC<any> = ({
  isOpen,
  handleClose,
  newCampaignState,
  handleInputChange,
  handleCreateCampaign,
}) => {
  return (
    <Dialog
      fullWidth={true}
      open={isOpen}
      onClose={handleClose}
      onBackdropClick={handleClose}
    >
      <DialogTitle>Add People To Campaign</DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          padding: 24,
        }}
      >
        <Row>
          <TextField
            label="Campaign Name"
            name="name"
            value={newCampaignState.name}
            onChange={handleInputChange}
            variant="filled"
            style={{ flex: 4 }}
          />
          <FormControl variant="filled" style={{ flex: 1 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={newCampaignState.status}
              onChange={handleInputChange}
              label="Status"
              name="status"
            >
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
            </Select>
          </FormControl>
        </Row>
        <TextField
          label="Description"
          name="description"
          value={newCampaignState.description}
          onChange={handleInputChange}
          variant="filled"
          style={{ marginTop: 16 }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 16,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCampaign}
          >
            Create
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
