import * as React from 'react';

import { Api } from '../utils/Api';

import { useInterval } from './useInterval';

interface HookParams {
  doPolling?: boolean;
  pollingInterval?: number;
}

export const useInboxPoll = ({
  doPolling = false,
  pollingInterval = 15000,
}: HookParams): any => {
  const [campaigns, setCampaigns] = React.useState([]);
  const [conversations, setConversations] = React.useState([]);

  const getInboxData = async () => {
    try {
      const [{ data: convos }, { data: camps }] = await Promise.all([
        Api.getConversations(),
        Api.getCampaigns(),
      ]);

      setConversations(convos.conversations);
      setCampaigns(camps.campaigns);
    } catch (error) {
      console.log('error', error);
    }
  };

  useInterval(getInboxData, doPolling ? pollingInterval : null);

  React.useEffect(() => {
    getInboxData();
  }, []);

  return {
    campaigns,
    conversations,
  };
};
