import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import * as React from 'react';

export const MessagePreview = ({
  name,
  recipientCount,
  lastMessage,
  lastMessageSentAt,
  selected,
  onClick,
}) => {
  return (
    <ListItem
      button
      style={{ padding: 16 }}
      selected={selected}
      onClick={onClick}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Avatar alt="CR">{name.substr(0, 2).toUpperCase()}</Avatar>
      </ListItemAvatar>
      {/* <ListItemText primary={name} secondary={lastMessage} /> */}

      <ListItemText
        primary={name}
        secondary={
          <>
            <Typography component="span" variant="body2" color="textPrimary">
              {lastMessage}
            </Typography>
            <br />
            {recipientCount !== null && (
              <Typography
                component="span"
                variant="caption"
                color="textSecondary"
              >
                {recipientCount} recipients in {name}.
              </Typography>
            )}
          </>
        }
      />

      <div>{lastMessageSentAt && <p>{lastMessageSentAt}</p>}</div>
    </ListItem>
  );
};
