import Axios from 'axios';

import { API_URL } from './constants';

interface IConversationParams {
  personId: string;
}

const token: string | null = localStorage.getItem('token');

const v1 = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

v1.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.reload();
      return;
    }

    return error;
  },
);

class API {
  public setEnvironment(incomingToken: string | null) {
    v1.defaults.headers = {
      Authorization: `Bearer ${incomingToken}`,
    };
  }
  //
  // ======= Auth
  //

  public async login(email: string, password: string) {
    try {
      const res = await v1.post('/auth', { email, password });

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  // Campaigns

  public async getCampaigns() {
    try {
      const res = await v1.get('/campaigns');

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  public async createCampaign(data: any) {
    try {
      const res = await v1.post('/campaigns', data);

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  public async updateCampaignParticipants(data: any) {
    try {
      const res = await v1.put('/campaigns/participants', data);

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  // Conversations

  public async getConversations() {
    try {
      const res = await v1.get('/conversations');

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  public async createConversation(data: IConversationParams) {
    try {
      const res = await v1.post('/conversations', data);

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  // Social Accounts

  public async getSocialAccounts() {
    try {
      const res = await v1.get('/social-accounts');

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  public async deleteSocialAccount(socialAccountId: string) {
    try {
      const res = await v1.delete(`/social-accounts/${socialAccountId}`);

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  // People

  public async getPeople() {
    try {
      const res = await v1.get('/people');

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  public async getPerson(personId: string) {
    try {
      const res = await v1.get(`/people/${personId}`);

      return { data: res.data.person };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  public async createPerson(data: any) {
    try {
      const res = await v1.post('/people', data);

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  public async updatePerson(personId: string, data: any) {
    try {
      const res = await v1.put(`/people/${personId}`, data);

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  public async deletePersonRate(rateId: string) {
    try {
      await v1.delete(`/rates/${rateId}`);

      return { success: true };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  public async deletePersonStat(statId: string) {
    try {
      await v1.delete(`/stats/${statId}`);

      return { success: true };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  // Broadcast Messages
  public async createBroadcastMessage(data: any) {
    try {
      const res = await v1.post('/broadcast-messages', data);

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  // Messages

  public async createMessage(data: any) {
    try {
      const res = await v1.post('/messages', data);

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  // Tags

  public async getTags() {
    try {
      const res = await v1.get('/tags');

      return { data: res.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }

  public async createImport(file: any) {
    try {
      const data = new FormData();
      data.append('csv', file);

      const res = await v1.post('/imports', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return { data: res?.data };
    } catch (error) {
      const message = error?.response?.data?.message || error?.message;
      return { error: message };
    }
  }
}

export const Api = new API();
