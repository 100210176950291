import { MetricType, RateType } from './typings';

export const formatRateLabel = (label: RateType) => {
  switch (label) {
    case RateType.Dedicated:
      return 'Dedc';
    case RateType.Integration:
      return 'Intg';
    case RateType.StoryOneFrame:
      return '1 Frame';
    case RateType.StoryThreeFrame:
      return '3 Frame';
    case RateType.Feed:
    default:
      return label;
  }
};

export const formatStatLabel = (label: MetricType) => {
  switch (label) {
    case MetricType.EngagementRate:
      return 'Eng Rate';
    case MetricType.FollowerCount:
      return 'Followers';
    case MetricType.StoryViews:
      return 'Story Views';
    default:
      return label;
  }
};
