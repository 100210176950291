import { DropzoneDialog } from 'material-ui-dropzone';
import * as React from 'react';

import { ToastContext } from '../../context/ToastContext';
import { Api } from '../../utils/Api';

interface ImportModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const ImportModal: React.FC<ImportModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const toast = React.useContext(ToastContext);

  const handleSave = async (files: any) => {
    const { data, error } = await Api.createImport(files?.[0]);

    if (data?.success) {
      toast.open({ message: 'Import Successful', severity: 'success' });
      return closeModal();
    }

    if (error) {
      toast.open({ message: error?.message, severity: 'error' });
    }
  };

  return (
    <DropzoneDialog
      dialogTitle="Import People"
      open={isOpen}
      onSave={handleSave}
      acceptedFiles={['text/csv']}
      filesLimit={1}
      useChipsForPreview={true}
      showPreviews={true}
      maxFileSize={5000000}
      onClose={closeModal}
    />
  );
};
