import * as React from 'react';
import {
  Typography,
  List,
  Link,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Email, Phone, Sms } from '@material-ui/icons';

import {
  IPerson,
  ISocialAccountStat,
  ISocialAccountRate,
} from '../../utils/typings';
import { SocialIcon } from '../SocialIcon';
import { formatNumbers } from '../../utils/formatNumbers';
import { getSocialLink } from '../../utils/getSocialLink';
import { formatRateLabel, formatStatLabel } from '../../utils/formatLabels';

interface Props {
  person: IPerson;
}

const useStyles = makeStyles((_theme) => ({
  root: {
    flex: 1,
  },
  iconRoot: {
    minWidth: 0,
    marginRight: 16,
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 12,
    lineHeight: 1,
  },
  listSubHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 12,
    lineHeight: 1,
  },
  listItem: {
    paddingLeft: 32,
  },
  emptyWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.5,
  },
}));

export const InboxConversationDetails: React.FC<Props> = ({ person }) => {
  const classes = useStyles();

  if (!person) {
    return (
      <div className={classes.emptyWrapper}>
        <Typography variant="body1">Select a conversation</Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 24,
          padding: 12,
        }}
      >
        <Typography variant="h5" style={{ flex: 1 }}>
          {person?.firstName} {person?.lastName}
        </Typography>
        <Button variant="outlined" component="a" href={`/people/${person?.id}`}>
          Edit Person
        </Button>
      </div>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem divider>
          <ListItemIcon className={classes.iconRoot}>
            <Email fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={person?.email} />
        </ListItem>
        <ListItem divider>
          <ListItemIcon className={classes.iconRoot}>
            <Phone fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`${person?.phoneNumber}`} />
        </ListItem>
      </List>

      <List
        component="nav"
        subheader={
          <ListSubheader component="div" className={classes.sectionTitle}>
            SOCIALS
          </ListSubheader>
        }
        className={classes.root}
      >
        {person?.socialAccounts?.map((social: any) => (
          <List
            key={social.id}
            component="nav"
            subheader={
              <ListSubheader component="div" className={classes.listSubHeader}>
                <SocialIcon socialType={social.type} fontSize="small" />
                <Link
                  href={getSocialLink(social)}
                  target="_blank"
                  style={{ marginLeft: 8 }}
                >
                  @{social?.username}
                </Link>
              </ListSubheader>
            }
          >
            <ListItem className={classes.listItem} divider>
              {social.stats.map((stat: ISocialAccountStat) => {
                const formatType =
                  stat.metric === 'EngagementRate' ? 'percent' : 'integer';
                const value = formatNumbers(formatType, stat.value);
                const label = formatStatLabel(stat.metric);
                return (
                  <ListItemText
                    key={stat?.id}
                    primary={value}
                    secondary={label}
                  />
                );
              })}
            </ListItem>
            <ListItem className={classes.listItem} divider>
              {social.rates.map((rate: ISocialAccountRate) => {
                const value = formatNumbers('currency', rate.min);
                const label = formatRateLabel(rate.type);
                return (
                  <ListItemText
                    key={rate?.id}
                    primary={value}
                    secondary={label}
                  />
                );
              })}
            </ListItem>
          </List>
        ))}
      </List>

      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" className={classes.sectionTitle}>
            CAMPAGINS
          </ListSubheader>
        }
      >
        {person?.campaigns?.map((campaign) => (
          <ListItem component="a" button href={`/campaigns/${campaign.id}`}>
            <ListItemIcon className={classes.iconRoot}>
              <Sms fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={campaign?.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
