import { ISocialAccount, SocialAccountEnum } from './typings';

export const getSocialLink = ({ type, username }: ISocialAccount) => {
  try {
    switch (type) {
      case SocialAccountEnum.Instagram:
        return `https://instagram.com/${username}`;
      case SocialAccountEnum.YouTube:
        return `https://youtube.com/c/${username}`;
      case SocialAccountEnum.TikTok:
        return `https://tiktok.com/@${username}`;

      default:
        return '';
    }
  } catch (error) {
    return '';
  }
};
