import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';

interface State {
  isOpen: boolean;
  message: string;
  autoHideDuration?: number;
  severity: 'success' | 'info' | 'warning' | 'error';
}

export interface IToastContext {
  toast: any;
  open: (args: Pick<State, 'message' & 'severity'>) => void;
}

export const ToastContext = React.createContext<IToastContext>(
  {} as IToastContext,
);

const initialState: State = {
  isOpen: false,
  message: '',
  autoHideDuration: 5000,
  severity: 'info',
};

interface Props {
  children: React.ReactNode;
}

export const ToastProvider: React.FC<Props> = ({ children }) => {
  const [toast, setToast] = React.useState<State>(initialState);

  React.useEffect(() => {
    setTimeout(() => {
      setToast(initialState);
      // tslint:disable-next-line:align
    }, toast.autoHideDuration);

    // eslint-disable-next-line
  }, [toast.isOpen === true]);

  const open = ({ message, severity }: any) => {
    setToast({
      ...toast,
      isOpen: true,
      message,
      severity: severity || 'info',
    });
  };

  return (
    <ToastContext.Provider
      value={{
        toast,
        open,
      }}
    >
      {toast.isOpen && (
        <Snackbar
          open={toast.isOpen}
          // message={toast.message}
          autoHideDuration={1000}
        >
          <Alert severity={toast.severity}>{toast.message}</Alert>
        </Snackbar>
      )}
      {children}
    </ToastContext.Provider>
  );
};
