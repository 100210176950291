import { uniq } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { DashboardWrapper } from '../../components/DashboardWrapper';
import { PersonForm } from '../../components/PersonForm';
import { ToastContext } from '../../context/ToastContext';
import { Api } from '../../utils/Api';

const emptyValues = {
  rates: {
    type: '',
    min: null,
    max: null,
    isActive: true,
  },
  stats: {
    metric: '',
    value: '',
  },
};

const emptySocialAccount = {
  username: '',
  type: '',
  rates: [emptyValues.rates],
  stats: [emptyValues.stats],
};

export const PersonDetails = ({ match }) => {
  const toast = React.useContext(ToastContext);
  const history = useHistory();

  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    dateOfBirth: new Date(),

    // location
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',

    socialAccounts: [emptySocialAccount],
    tags: [],
  });
  const [tags, setTags] = React.useState([]);

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const init = async () => {
    try {
      const [{ data: person }, { data: tagsData }] = await Promise.all([
        Api.getPerson(match.params.id),
        Api.getTags(),
      ]);
      setTags(uniq(tagsData.data.tags?.flat()));
      setState({
        ...person,
        addressLine1: person?.location?.addressLine1,
        addressLine2: person?.location?.addressLine2,
        city: person?.location?.city,
        state: person?.location?.state,
        postalCode: person?.location?.postalCode,
        country: person?.location?.country,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        gender,
        dateOfBirth,
        addressLine1,
        addressLine2,
        city,
        postalCode,
        country,
        socialAccounts,
      } = state;
      const { error } = await Api.updatePerson(match.params.id, {
        firstName,
        lastName,
        email,
        phoneNumber,
        gender: !gender ? null : gender,
        dateOfBirth,
        tags: state.tags?.flat(),
        location: {
          addressLine1,
          addressLine2,
          city,
          state: state.state,
          postalCode,
          country,
        },
        socialAccounts,
      });

      if (error) {
        throw new Error(error);
      }

      toast.open({ message: `Successfully updated ${firstName}` });
      history.push('/people');
    } catch (error) {
      toast.open({ message: error?.message, severity: 'error' });
    }
  };

  return (
    <DashboardWrapper title={`Update ${state?.firstName}`} history={history}>
      <div style={{ width: '100%', paddingBottom: '5vh' }}>
        <PersonForm
          title={`Update ${state?.firstName}`}
          state={state}
          setState={setState}
          tags={tags}
          handleSubmit={handleSubmit}
          toast={toast}
        />
      </div>
    </DashboardWrapper>
  );
};
