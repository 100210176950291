import {
  Button,
  ButtonGroup,
  Chip,
  Link,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import { Edit, Sms } from '@material-ui/icons';
import { intersection } from 'lodash';
import * as moment from 'moment';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import * as React from 'react';

import { FormattedNumberInput } from '../../components/FormattedNumberInput';
import { Row } from '../../components/Row';
import { SocialIcon } from '../../components/SocialIcon';
import { Api } from '../../utils/Api';
import { formatStatLabel } from '../../utils/formatLabels';
import {
  formatCurrency,
  formatInteger,
  formatNumbers,
  formatPercentage,
} from '../../utils/formatNumbers';
import { getSocialLink } from '../../utils/getSocialLink';
import { MetricType } from '../../utils/typings';

const parseJson = (obj: any) => {
  try {
    return JSON.parse(obj);
  } catch (error) {
    return obj;
  }
};

export const socialListTableColumns = (socialAccounts: any, history: any) => {
  const createConversation = async (personId: string) => {
    const { data } = await Api.createConversation({ personId });
    return data?.conversation?.id;
  };

  const navigateToConversation = async ({ conversationId, personId }: any) => {
    const id = conversationId || (await createConversation(personId));
    history.push(`/conversations/${id}`);
  };

  const returnFilters: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: false,
        download: false,
      },
    },
    {
      name: 'socialAccountType',
      label: 'Social Account Type',
      options: {
        filter: true,
        display: false,
        filterType: 'multiselect',
      },
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'username',
      label: 'Username',
      options: {
        filter: false,
        customBodyRender: (username: any, tableMeta: any) => {
          const socialAccountId: string = tableMeta?.rowData?.[0];
          const account = socialAccounts?.find(
            (node: any) => node?.id === socialAccountId,
          );

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <SocialIcon
                socialType={account?.socialAccountType}
                style={{ marginRight: 8 }}
              />
              <Link href={getSocialLink(account)} target="_blank">
                @{account?.username}
              </Link>
            </div>
          );
        },
      },
    },

    {
      name: 'location',
      label: 'Location',
      options: {
        filterType: 'textField',
        customBodyRender: (loc: any) => {
          try {
            const location = parseJson(loc);
            return `${location?.city}, ${location.country}`;
          } catch (error) {
            return '';
          }
        },
      },
    },
    {
      name: 'tags',
      label: 'Tags',
      options: {
        customBodyRender: (tags: any) => {
          return tags?.map((tag: string) => <Chip key={tag} label={tag} />);
        },
        filter: true,
        filterType: 'multiselect',
        sort: false,
      },
    },
    {
      name: 'dateOfBirth',
      label: 'Age',
      options: {
        filter: true,
        filterType: 'custom',
        filterList: ['0', '80'],
        customFilterListOptions: {
          render: (v) =>
            customFilterListOptionsRender('Age', 'integer', v[0], v[1]),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        filterOptions: {
          names: [],
          logic: (age: any, filters: any) => {
            if (
              !filters.length ||
              (filters[0] === '0' && filters[1] === '80')
            ) {
              return false;
            }
            return age < filters[0] || age > filters[1];
          },
          display: (filterList: any, onChange, index: any, column) => (
            <div>
              <Typography id="range-slider" gutterBottom>
                Age Range
              </Typography>
              <Slider
                value={[filterList[index][0], filterList[index][1]]}
                onChange={(_e, value) => {
                  filterList[index][0] = value[0];
                  filterList[index][1] = value[1];
                  onChange(filterList[index], index, column);
                }}
                valueLabelDisplay="auto"
                max={80}
              />
            </div>
          ),
        },
        customBodyRender: (dateOfBirth: any) => {
          return moment().diff(dateOfBirth, 'years').toString();
        },
      },
    },
    {
      name: 'engagementRate',
      label: formatStatLabel(MetricType.EngagementRate),
      options: {
        customBodyRender: (val: number) => formatPercentage(val),
        filter: true,
        filterType: 'custom',
        filterList: ['0', '100'],
        customFilterListOptions: {
          render: (v) =>
            customFilterListOptionsRender('Engagement', 'percent', v[0], v[1]),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        filterOptions: {
          names: [],
          logic: (engagementRate: any, filters: any) => {
            if (
              filters.length === 0 ||
              (filters[0] === '0' && filters[1] === '100')
            ) {
              console.log('skip filters');
              return false;
            }

            const val = Number(engagementRate?.split('%')?.[0]);
            return val < filters[0] || val > filters[1];
          },
          display: (filterList: any, onChange, index: any, column) => (
            <div>
              <Typography id="range-slider" gutterBottom>
                Engagement Rate
              </Typography>
              <Slider
                value={[filterList[index][0], filterList[index][1]]}
                onChange={(_e, value) => {
                  filterList[index][0] = value[0];
                  filterList[index][1] = value[1];
                  onChange(filterList[index], index, column);
                }}
                valueLabelDisplay="auto"
                max={100}
              />
            </div>
          ),
        },
      },
    },
    {
      name: 'followerCount',
      label: formatStatLabel(MetricType.FollowerCount),
      options: {
        customBodyRender: (val: number) => formatInteger(val),
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) =>
            customFilterListOptionsRender('Followers', 'integer', v[0], v[1]),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        filterOptions: {
          names: [],
          logic: (followerCount: any, filters: any) => {
            if (!!filters.length) {
              return followerCount < filters[0] || followerCount > filters[1];
            }
            return false;
          },
          display: (filterList: any, onChange, index: any, column) => (
            <RenderMinMaxInputs
              title="Follower Count"
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'storyViews',
      label: formatStatLabel(MetricType.StoryViews),
      options: {
        customBodyRender: (val: number) => formatInteger(val),
        filter: true,
        filterType: 'custom',
        filterList: ['0', '100000000'],
        customFilterListOptions: {
          render: (v) =>
            customFilterListOptionsRender('Views', 'integer', v[0], v[1]),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        filterOptions: {
          names: [],
          logic: (storyViews: any, filters: any) => {
            if (
              !filters.length ||
              (filters[0] === '0' && filters[1] === '100000000')
            ) {
              return false;
            }
            return storyViews < filters[0] || storyViews > filters[1];
          },
          display: (filterList: any, onChange, index: any, column) => (
            <RenderMinMaxInputs
              title="Story Views"
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      name: 'rateValues',
      label: 'Rates',
      options: {
        filter: true,
        filterType: 'custom',
        filterList: ['0', '100000'],
        customFilterListOptions: {
          render: (v: any) =>
            customFilterListOptionsRender('Rates', 'currency', v[0], v[1]),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        filterOptions: {
          names: [],
          logic: (rates: any, filters: any) => {
            if (
              !filters.length ||
              (filters[0] === '0' && filters[1] === '100000')
            ) {
              return false;
            }
            return !rates?.some((rate) => {
              const lower = rate?.min >= filters[0];
              const upper = rate?.min <= filters[1];

              return lower && upper;
            });
          },
          display: (filterList: any, onChange, index: any, column) => (
            <RenderMinMaxInputs
              title="Rates"
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
        customBodyRender: (rates: any[], tableMeta: any) => {
          return (
            <>
              {rates?.map((node) => {
                if (!node?.min) {
                  return;
                }
                return (
                  <span key={node.id} style={{ display: 'block' }}>
                    <strong style={{ marginRight: 12 }}>{node?.type}</strong>
                    {formatCurrency(node?.min)}
                    {node?.max && ` to ${formatCurrency(node?.max)}`}
                  </span>
                );
              })}
            </>
          );
        },
      },
    },
    {
      name: 'rateTypes',
      label: 'Rate Types',
      options: {
        display: false,
        filter: true,
        filterType: 'multiselect',
        filterOptions: {
          logic: (rateTypes: any, filters: any) =>
            !intersection(rateTypes, filters)?.length,
        },
      },
    },
    {
      name: 'person',
      label: 'Actions',
      options: {
        filter: false,
        download: false,
        sort: false,
        customBodyRender: (person) => {
          const personId = person?.id;
          const conversationId = person?.conversation?.id;
          return (
            <ButtonGroup variant="contained">
              <Button onClick={() => history.push(`/people/${personId}`)}>
                <Edit />
              </Button>
              <Button
                onClick={async () =>
                  await navigateToConversation({ conversationId, personId })
                }
              >
                <Sms />
              </Button>
            </ButtonGroup>
          );
        },
      },
    },
  ];

  return returnFilters;
};

export const socialListTableOptions = (
  handleRowSelectionChange: any,
  selectedRows: any[],
  setIsModalOpen: any,
): MUIDataTableOptions => ({
  searchOpen: true,
  filter: true,
  sort: true,
  rowsPerPage: 25,
  print: false,
  rowsPerPageOptions: [25, 100, 200, 300, 400, 500],
  download: false,
  customToolbarSelect: () => (
    <Button onClick={() => setIsModalOpen(true)}>
      Add Selected to Campaign
    </Button>
  ),
  draggableColumns: {
    enabled: true,
  },
  selectableRows: 'multiple',
  selectToolbarPlacement: 'above',
  onRowSelectionChange: handleRowSelectionChange,
  rowsSelected: selectedRows,
  tableBodyHeight: '100%',
  tableBodyMaxHeight: '100%',
  selectableRowsOnClick: true,
});

interface IMinMaxInputs {
  title: string;
  filterList: any;
  onChange: (list: any, index: number, column: any) => void;
  index: number;
  column: any;
}

export const RenderMinMaxInputs: React.FC<IMinMaxInputs> = ({
  title,
  filterList,
  onChange,
  index,
  column,
}) => {
  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: 'min' | 'max',
  ) => {
    const value = event.target?.value;
    const inputIndex = type === 'min' ? 0 : 1;
    filterList[index][inputIndex] = value;
    onChange(filterList?.[index], index, column);
  };

  return (
    <div>
      <Typography gutterBottom>{title}</Typography>
      <Row>
        <TextField
          variant="filled"
          label="Min"
          value={filterList?.[index]?.[0]}
          InputProps={{ inputComponent: FormattedNumberInput as any }}
          onChange={(e) => handleOnChange(e, 'min')}
          fullWidth
        />
        <TextField
          variant="filled"
          label="Max"
          value={filterList?.[index]?.[1]}
          InputProps={{ inputComponent: FormattedNumberInput as any }}
          onChange={(e) => handleOnChange(e, 'max')}
          fullWidth
        />
      </Row>
    </div>
  );
};

const customFilterListOptionsRender = (
  label: string,
  style: 'currency' | 'percent' | 'integer' | any,
  min: number,
  max: number,
) => {
  if (min && max) {
    return [
      `${label} (Min): ${formatNumbers(style, min)}`,
      `${label} (Max): ${formatNumbers(style, max)}`,
    ];
  } else if (min) {
    return `${label} (Min): ${formatNumbers(style, min)}`;
  } else if (max) {
    return `${label} (Max): ${formatNumbers(style, max)}`;
  }
  return false;
};

const customFilterListOptionsUpdate = (filterList, filterPos, index) => {
  if (filterPos === 0) {
    filterList[index].splice(filterPos, 1, '');
  } else if (filterPos === 1) {
    filterList[index].splice(filterPos, 1);
  } else if (filterPos === -1) {
    filterList[index] = [];
  }

  return filterList;
};
