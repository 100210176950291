import { Instagram, MusicNote, YouTube } from '@material-ui/icons';
import * as React from 'react';

export const SocialIcon = ({ socialType, ...rest }) => {
  switch (socialType) {
    case 'Instagram':
      return <Instagram {...rest} />;
    case 'YouTube':
      return <YouTube {...rest} />;
    case 'TikTok':
      return <MusicNote {...rest} />;
    default:
      return null;
  }
};
