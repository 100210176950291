import MomentUtils from '@date-io/moment';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import './assets/flex.css';
import './assets/main.css';
import { ToastProvider } from './context/ToastContext';
import { CreatePerson } from './screens/CreatePerson';
import { Inbox } from './screens/Inbox';
import { Login } from './screens/Login';
import { NotFound } from './screens/NotFound';
import { PeopleScreen } from './screens/People';
import { PersonDetails } from './screens/PersonDetails';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ff0972',
      light: '#ffb214',
      dark: '#222',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      root: {},
    },
    MuiAppBar: {
      root: {
        background: 'linear-gradient(90deg,#ffb214,#ff0972)',
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'unset',
        overflowX: 'unset',
        overflowY: 'unset',
        scrollBehavior: 'smooth',
      },
      responsiveBase: {
        paddingBottom: 165,
      },
      tableRoot: {
        paddingBottom: 52,
      },
      paper: {
        height: '100%',
        overflow: 'hidden',
      },
    },
    MUIDataTableToolbar: {
      root: {
        top: 0,
        position: 'sticky',
        backgroundColor: '#303030',
        zIndex: 1200,
      },
    },
    MUIDataTableFilterList: {
      root: {
        top: 64,
        margin: 0,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        position: 'sticky',
        backgroundColor: '#303030',
        zIndex: 1200,
      },
    },
    MUIDataTableHead: {
      main: {
        top: 0,
        position: 'sticky',
        backgroundColor: '#303030',
        zIndex: 1200,
      },
    },
    MUIDataTableFooter: {
      root: {
        bottom: -1,
        position: 'sticky',
        backgroundColor: '#303030',
        zIndex: 1200,
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        position: 'relative',
        backgroundColor: '#303030',
      },
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        position: 'relative',
        backgroundColor: '#303030',
      },
    },
  },
} as any);

const PrivateRoute = ({ component, ...props }: any) => {
  const token = localStorage.getItem('token');

  if (!token) {
    return <Redirect to="/" />;
  }

  return <Route {...props} component={component} />;
};

const LoginRoute = ({ component, ...props }: any) => {
  const token = localStorage.getItem('token');

  if (token) {
    return <Redirect to="/people" />;
  }

  return <Route {...props} component={component} />;
};

render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <LoginRoute exact path="/" component={Login} />
            <PrivateRoute exact path="/people" component={PeopleScreen} />
            <PrivateRoute exact path="/people/new" component={CreatePerson} />
            <PrivateRoute exact path="/people/:id" component={PersonDetails} />
            <PrivateRoute exact path="/conversations" component={Inbox} />
            <PrivateRoute exact path="/campaigns" component={Inbox} />
            <PrivateRoute exact path="/conversations/:id" component={Inbox} />
            <PrivateRoute exact path="/campaigns/:id" component={Inbox} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </ToastProvider>
    </ThemeProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root'),
);
