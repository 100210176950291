import { Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
  title: string;
  description?: string;
  style?: React.CSSProperties;
}

export const GroupLabel: React.FC<Props> = ({ title, description }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 2,
      paddingRight: 32,
    }}
  >
    <Typography variant="h4">{title}</Typography>
    {description && <Typography variant="subtitle1">{description}</Typography>}
  </div>
);
