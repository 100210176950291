import * as React from 'react';

interface IRow {
  style?: React.CSSProperties;
}

export const Row: React.FC<IRow> = ({ children, style }) => (
  <div className="u-row" style={style}>
    {children}
  </div>
);
